<template>
<div class="container">
    <div class="analysis-block">
        <div class="title">
            <colorful-title title="销售情况"/>
        </div>
        <el-row class="content overview">
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">售前访问量</div>
                <div class="count">{{overview.number1}}</div>
                <el-tooltip placement="top" :content="`售前访问量:${overview.number1}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">售前访客数</div>
                <div class="count">{{overview.number2}}</div>
                <el-tooltip placement="top" :content="`售前访客数:${overview.number2}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">付费用户数</div>
                <div class="count">{{overview.number3}}</div>
                <el-tooltip placement="top" :content="`付费用户数:${overview.number3}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">支付金额(元)</div>
                <div class="count">￥{{overview.number4 | numFixed}}</div>
                <el-tooltip placement="top" :content="`支付金额:${overview.number4 / 100}元`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">转化率</div>
                <div class="count">{{overview.number5}}%</div>
                <el-tooltip placement="top" :content="`转化率:${overview.number5}%`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='4' :md='8' :sm='12' :xs='24'>
                <div class="title">订阅量</div>
                <div class="count">{{overview.number6}}</div>
                <el-tooltip placement="top" :content="`订阅量:${overview.number6}`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
        </el-row>
        <div class="content">
            <v-chart :options="trendOptions1" class="trend-chart"></v-chart>
        </div>
        <div class="content">
            <div class="table-container">
                <el-table :data="sell.tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column prop="create_time" label="时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="售前访问量" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="售前访客数" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="付费用户" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="支付金额(元)" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            ￥{{scope.row.price | numFixed}}
                        </template>
                    </el-table-column>
                    <el-table-column label="订阅量" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="prev, pager, next" background
                    @current-change="sellPageChange" :current-page="sell.currentPage"
                    :total="sell.total" :page-size="10">
                </el-pagination>
            </div>
        </div>
    </div>
    <div class="analysis-block">
        <div class="title">
            <colorful-title title="使用情况"/>
        </div>
        <el-row class="content overview">
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">订阅用户浏览量</div>
                <div class="count">{{overview.number7}}</div>
                <el-tooltip placement="top" :content="`订阅用户浏览量:${overview.number7}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">浏览用户数</div>
                <div class="count">{{overview.number8}}</div>
                <el-tooltip placement="top" :content="`浏览用户数:${overview.number8}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">评论数</div>
                <div class="count">{{overview.number9}}</div>
                <el-tooltip placement="top" :content="`评论数:${overview.number9}`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
            <el-col class="item" :lg='6' :sm='12' :xs='24'>
                <div class="title">评论用户数</div>
                <div class="count">{{overview.number10}}</div>
                <el-tooltip placement="top" :content="`评论用户数:${overview.number10}人`">
                    <i class="iconfont icon-hint"/>
                </el-tooltip>
            </el-col>
        </el-row>
        <div class="content">
            <v-chart :options="trendOptions1" class="trend-chart"></v-chart>
        </div>
        <div class="content">
            <div class="table-container">
                <el-table :data="used.tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column prop="create_time" label="时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="订阅用户浏览量" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="浏览用户数" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="评论数" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time}}
                        </template>
                    </el-table-column>
                    <el-table-column label="评论用户数" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.price}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="prev, pager, next" background
                    @current-change="usedPageChange" :current-page="used.currentPage"
                    :total="used.total" :page-size="10">
                </el-pagination>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/grid";
import "echarts/lib/component/legend";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";

const colors = ['#1890FF', '#2FC25B'];

export default {
    components: {
        NoData: () => import('@/components/Empty.vue'),
        ColorfulTitle: () => import('@/components/common/ColorfulTitle'),
        "v-chart": ECharts,
    },
    props: {},
    data() {
        return {
            overview: {
                number1: 482,
                number2: 131,
                number3: 145,
                number4: 1300,
                number5: 14,
                number6: 223,
                number7: 482,
                number8: 131,
                number9: 15,
                number10: 13,
            },
            trendOptions1: {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontSize: 14,
                    }
                },
                grid: {
                    width: 'auto',
                    show: false,
                    containLabel: false,
                    left: '5%',
                    right: '5%'
                },
                legend: {
                    data: ['学习人数', '人均学习时长(分钟)']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EEF2F5',
                            }
                        },
                        axisLabel: {
                            color: "rgba(0, 0, 0, 0.65)"
                        },
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            label: {
                                backgroundColor: '#333',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '{point|}{text|学习人数}',
                        min: 0,
                        max: 250,
                        position: 'left',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'left',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[0],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]

                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '{point|}{text|人均学习时长(分钟)}',
                        min: 0,
                        max: 250,
                        position: 'right',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'right',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[1],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 66, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                ],
                series: [
                    {
                        name: '学习人数',
                        type: 'line',
                        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
                    },
                    {
                        name: '人均学习时长(分钟)',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
                    },
                ]
            },
            trendOptions2: {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    textStyle: {
                        fontSize: 14,
                    }
                },
                grid: {
                    width: 'auto',
                    show: false,
                    containLabel: false,
                    left: '5%',
                    right: '5%'
                },
                legend: {
                    data: ['完成学习人数', '完课率']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#EEF2F5',
                            }
                        },
                        axisLabel: {
                            color: "rgba(0, 0, 0, 0.65)"
                        },
                        data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                        axisPointer: {
                            label: {
                                backgroundColor: '#333',
                            }
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '{point|}{text|完成学习人数}',
                        min: 0,
                        max: 250,
                        position: 'left',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            align: 'left',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[0],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                    },
                    {
                        type: 'value',
                        name: '{point|}{text|完课率}',
                        min: 0,
                        max: 100,
                        position: 'right',
                        axisLine: { show: false, },
                        axisTick: { show: false, },
                        nameTextStyle: {
                            // align: 'right',
                            verticalAlign: 'middle',
                            height: 55,
                            rich: {
                                point: {
                                    width: 6,
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: colors[1],
                                },
                                text: {
                                    fontSize: 14,
                                    color: 'rgba(0, 0, 0, 0.65)',
                                    padding: [0, 0, 0, 4]
                                }
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#E9E9E9',
                                type: 'dashed',
                            }
                        },
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    },
                ],
                series: [
                    {
                        name: '完成学习人数',
                        type: 'line',
                        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
                    },
                    {
                        name: '完课率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 75.6, 82.2, 48.7, 18.8, 6.0, 2.3],
                    },
                ]
            },
            sell: {
                total: 0,
                currentPage: 1,
                tableData: [],
            },
            used: {
                total: 0,
                currentPage: 1,
                tableData: [],
            },
        }
    },
    created() {},
    mounted() {},
    methods: {
        sellPageChange(page) {
            this.sell.currentPage = page;
            this.getSellTableData();
        },
        getSellTableData() {

        },

        usedPageChange(page) {
            this.used.currentPage = page;
            this.getUsedTableData();
        },
        getUsedTableData() {

        }
    },
}
</script>

<style lang="scss" scoped>
.analysis-block {
    > .content {
        margin-top: 20px;
        .page-container {
            display: flex;
            justify-content: center;
            padding: 20px 0;
        }
    }
    .trend-chart {
        width: 100%;
        height: 380px;
    }
}

</style>